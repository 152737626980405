<template>
  <b-card no-body>
    <div v-if="!$wait.is('loading-get-setting')">
      <b-row no-gutters>
        <b-col
          cols="12"
          class="bg-primary"
        >
          <div class="d-flex flex-row justify-content-between align-items-center">
            <div>
              <b-button
                variant="primary"
                class="btn-icon"
                :disabled="$wait.is('loading-on-save')"
                @click="$router.go(-1)"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
                {{ this.$t('Previous Page') }}
              </b-button>
            </div>
            <div class="card-header text-white font-weight-bold h4 ">
              {{ $t('Account Info') }}
            </div>
            <div class="pr-1">
              <b-button
                variant="dark"
                class="btn-icon"
                size="lg"
                :disabled="$wait.is('loading-on-save')"
                @click="onSave"
              >
                <feather-icon icon="SaveIcon" />
                บันทีก
              </b-button>
            </div>
          </div>
        </b-col>
        <b-col
          cols="12"
          class="p-2"
        >
          <!-- form -->
          <PartnersKeysCreateEditForm
            ref="formHandle"
            @onGenerateNewApiKey="onGenerateNewApiKey"
          />
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <loading />
    </div>
  </b-card>
</template>

<script>
import store from '@/store'
import PartnersKeysCreateEditForm from './PartnersKeysCreateEditForm.vue'

export default {
  components: {
    PartnersKeysCreateEditForm,
  },
  data() {
    return {
    }
  },
  computed: {
    userData() {
      return store.getters['auth/user']
    },
  },

  mounted() {
    this.onLoad()
  },

  methods: {
    async onLoad() {
      this.$wait.start('loading-on-get-partnerskeys-setting-by-id')
      try {
        const { data } = await this.$http.get(`/partnerskeys/edit/${this.$route.params.id}`, {
        })
        if (data.success) {
          this.$refs.formHandle.setFormData({
            // ...this.$refs.formHandle.getDefaultFormData(),
            username: data.data.agentUsername,
            contact: data.data?.contact ?? '',
            apiKey: data.data.key,
            active: data.data.active,
            ips: data.data.ips,
          })
        } else {
          this.$swal({
            icon: 'error',
            title: this.$t(data.status.message || 'Error'),
          })
        }
      } finally {
        this.$wait.end('loading-on-get-partnerskeys-setting-by-id')
      }
    },
    async onSave() {
      if (!await this.$refs.formHandle.validate()) {
        return
      }

      const formData = this.$refs.formHandle.getFormData()

      this.$wait.start('loading-on-save')
      try {
        const { data } = await this.$http.patch(`/partnerskeys/edit/${this.$route.params.id}`, formData)
        if (data.success) {
          this.$nextTick(() => {
            this.$swal({
              icon: 'success',
              title: this.$t('ACCOUNT_SAVE_SUCCESS'),
            }).then(() => {
            })
          })
        } else {
          this.$swal({
            icon: 'error',
            title: this.$t(data.status.message || 'Error'),
          })
        }
      } finally {
        this.$wait.end('loading-on-save')
      }
    },
    async onGenerateNewApiKey() {
      this.$wait.start('loading-on-save')
      try {
        const { data } = await this.$http.get(`/partnerskeys/generate-api-key/${this.$route.params.id}`)
        if (data.success) {
          this.$refs.formHandle.setFormData({
            apiKey: data.data.key,
          })

          this.$nextTick(() => {
            this.$swal({
              icon: 'success',
              title: data.data.key,
            }).then(() => {
            })
          })
        } else {
          this.$swal({
            icon: 'error',
            title: this.$t(data.status.message || 'Error'),
          })
        }
      } finally {
        this.$wait.end('loading-on-save')
      }
    },
  },
}
</script>
